/* eslint no-console:0 */
// This is the entrypoint for the `roxybar` child theme. The files
// imported here will be included on every page of the roxybar website.
//
// To reference this file, add <%= javascript_pack_tag 'roxybar' %> to the appropriate
// layout file, like frontend/roxybar/layouts/application.haml

import 'shared/assets/css'
import 'roxybar/components/header/header'
import 'roxybar/components/footer/footer'
import 'roxybar/components/newsletter_signup/newsletter_signup'
import 'roxybar/components/event_inquiry/event_inquiry'
import 'roxybar/components/popup_notification/popup_notification'
import 'shared/components'
import 'shared/assets/javascript'
import './css'
import './javascript'
